<template>
    <div>
        <v-app-bar
            fixed
            color="primary">

             <v-btn 
                icon
                to="/">
                <v-icon 
                    color="white" medium>mdi-arrow-right</v-icon>
            </v-btn>
            
            <v-toolbar-title class="white--text mr-n6 subtitle-2">
                שמורים
            </v-toolbar-title>

           
            <v-spacer/>

          
           
            
        </v-app-bar>
    </div>

</template>


<script>
export default {
    data() {
        return {
            
        }
    }
    
}
</script>